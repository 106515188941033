
































import Vue from 'vue';
import { ISettingsDataservice } from '../../../shared/src/dataservices/ISettingsDataservice';
import { IImageDataservice } from '../../../shared/src/dataservices/IImageDataservice';
import { IMetadataService } from '../../../shared/src/services/IMetadataService';
import { Category } from '../../../shared/src/entities/Category';
import { ICategoryDataservice } from '../../../shared/src/dataservices/ICategoryDataservice';
import { CategoryStatus } from '../../../shared/src/entities/CategoryStatus';

export default Vue.extend({
  inject: {
    categoryDataservice: 'categoryDataservice',
    productDataservice: 'productDataservice',
    imageDataservice: 'imageDataservice',
    metadataService: 'metadataService',
    settingsDataservice: 'settingsDataservice'
  },
  watch: {
    pagename: {
      immediate: true,
      handler(newValue): void {
        ((this as any).metadataService as IMetadataService).setTitle(newValue);
      }
    }
  },
  computed: {
    pagename(): string {
      return ((this as any).settingsDataservice as ISettingsDataservice).shopName;
    },
    categoriesSorted(): Category[] {
      let categories = ((this as any).categoryDataservice as ICategoryDataservice).categories;
      categories = categories.filter( (category) => category.status === CategoryStatus.ACTIVE);
      return ((this as any).categoryDataservice as ICategoryDataservice).sortCategoriesBySortIndex(categories);
    }
  }
});
